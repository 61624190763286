import jwtDecode from 'jwt-decode';
import * as moment from 'moment';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import jszip from 'jszip';

const axios = require('axios');
const FormData = require('form-data');

/* get refresh token from localstorage
then call refresh token endpoint using the refresh token
in order to refresh the auth token
the frontend should detect if the auth token has expired (in which case they should refresh)
should also detect if the refresh token has expired (in which case you'll be redirected to login)
on purchase (i.e. when a student will get more permissions) get the new token. Parse the token to
see which courses are available (via course IDs passed back from JWT).
Create a standard "auth object" which is used throughout the site
on any page that needs to make access decisions, parse the JWT (includes allowed paths),
update the auth object, determine whether
redirect is required.
*/

const isBrowser = typeof window !== 'undefined';

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
	if (!isBrowser) {
		return;
	}
	const url = `${process.env.GATSBY_API_BASE_URI}/api/v1/login/refresh-token`;
	const headers = {};
	const token = localStorage.getItem('token');
	if (token == null) {
		return Promise.resolve();
	}
	const refreshToken = token && JSON.parse(token)?.refresh_token;
	const decodedRefreshToken = jwtDecode(refreshToken);
	if (decodedRefreshToken.exp < new Date().getTime() / 1000) {
		return Promise.resolve();
	}
	if (!refreshToken) {
		return Promise.resolve();
	}

	headers.Authorization = `Bearer ${refreshToken}`;
	const options = {
		method: 'POST',
		headers,
		url,
	};
	return axios(options).then((tokenRefreshResponse) => {
		localStorage.setItem('token', JSON.stringify(tokenRefreshResponse.data));
		failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`;
		return Promise.resolve();
	});
};

class FastAPIClient {
	constructor(schoolExternalId = 'default') {
		this.config = {
			apiBasePath: process.env.GATSBY_API_BASE_URI,
			schoolExternalId,
		};
		this.login = this.login.bind(this);
		this.apiClient = this.getApiClient(this.config);
		this.apiClientEmpty = this.getApiClientEmpty(this.config);
	}

	/* ----- Authentication & User Operations ----- */

	/* Authenticate the user with the backend services.
	 * The same JWT should be valid for both the api and cms */
	login(username, password) {
		if (!isBrowser) {
			return;
		}
		delete this.apiClient.defaults.headers.Authorization;
		const form_data = new FormData();
		const grant_type = 'password';
		const client_id = this.config.schoolExternalId;
		const item = { grant_type, client_id, username, password };
		for (const key in item) {
			form_data.append(key, item[key]);
		}

		return this.apiClient.post('/login/access-token', form_data).then(
			(resp) => {
				this.storeToken(JSON.stringify(resp.data));
				return this.fetchUser();
			},
			(error) => {
				throw error?.response?.data?.detail || 'Unknown error during signin';
			}
		);
	}

	createTempStudentAccessToken(school_external_id, authorisation_code) {
		delete this.apiClient.defaults.headers.Authorization;
		return this.apiClient.post(`/schools/${school_external_id}/student-token`, { authorisation_code }).then(
			(resp) => {
				this.storeToken(JSON.stringify(resp.data));
				return this.fetchUser();
			},
			(error) => {
				if (error?.response?.status == 401) {
					alert(
						'Unable to log in as a test student. The authorisation_code has expired. Try again by clicking a fresh preview link in the author school editor. '
					);
					throw 'authorisation_code has expired';
				} else {
					throw (
						error?.response?.data?.detail ||
						'Unknown error while fetching access_token from authorisation_code'
					);
				}
			}
		);
	}

	storeToken(token) {
		localStorage.setItem('token', token);
		this.apiClient = this.getApiClient(this.config);
		// document.cookie = "session = " + token;
	}

	fetchUser() {
		if (!isBrowser) {
			return;
		}
		return this.apiClient.get('/users/me').then((response) => {
			const user = JSON.stringify(response.data);
			localStorage.setItem('user', user);
			return response.data;
		});
	}

	/* ----- Token Management ----- */

	refresh(refreshToken) {
		if (!isBrowser) {
			return;
		}
		// TODO use interceptors
		const url = `${process.env.GATSBY_API_BASE_URI}/api/v1/login/refresh-token`;
		const headers = {};
		headers.Authorization = `Bearer ${refreshToken}`;
		const options = {
			method: 'POST',
			headers,
			url,
		};
		return axios(options).then((response) => {
			const token = JSON.stringify(response.data);
			this.storeToken(token);
			return token;
		});
	}

	register(email, password, fullName) {
		const formData = {
			email,
			password,
			full_name: fullName,
			is_active: true,
			client_id: this.config.schoolExternalId,
		};
		return this.apiClient.post('/users/signup', formData).then((resp) => {
			this.login(email, password);
			return resp.data;
		});
	}

	getCurrentUser() {
		if (!isBrowser) {
			return;
		}
		return localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
	}

	getToken() {
		if (!isBrowser) {
			return;
		}
		return localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'));
	}

	// deprecated, see coursesFromJWT in theme
	checkEnrollments(enrolments) {
		const enrolledCourses = [];
		if (!enrolments) {
			return enrolledCourses;
		}
		for (let i = 0; i < enrolments.length; i++) {
			for (let j = 0; j < enrolments[i].courses.length; j++) {
				enrolledCourses.push(enrolments[i].courses[j].id);
				// TODO enrollments can expire
			}
		}
		const uniqEnrolledCourses = [...new Set(enrolledCourses)];
		return uniqEnrolledCourses;
	}

	couponCalc(code, priceId) {
		return this.apiClient.get(
			`/school_coupons/calc/?coupon_code=${code}&price_id=${priceId}`
		).then(({ data }) => data)
	}

	async updateAuthorization() {
		// This logic seems to be sort of duplicated in refreshAuthLogic()
		if (!isBrowser) {
			return;
		}
		const token = this.getToken();
		if (token?.refresh_token) {
			const updatedToken = await this.refresh(token.refresh_token);
			const userDetails = await this.fetchUser();
			return true
		} else {
			console.log('Cannot updateAuthorization, not logged in...');
			return false
		}
	}

	async fetchComments(payload) {
		if (!isBrowser) {
			return;
		}
		const { course_id, lecture_id, page_number } = payload;
		const page_size = 20;
		let queryParams = `?course_id=${course_id}&lecture_id=${lecture_id}&page_number=${page_number}&page_size=${page_size}`;

		return this.apiClient.get(
			`/comments/${queryParams}`
		).then(({ data }) => data)
	}

	async updateComment(payload) {
		if (!isBrowser) {
			return;
		}
		const body = JSON.stringify(payload);
		return this.apiClient.put(
			`/comments/`, body)
			.then((resp) => resp);
	}

	async createComment(payload) {
		if (!isBrowser) {
			return;
		}

		const body = JSON.stringify(payload);
		return this.apiClient.post(
			`/comments/`, body)
			.then((resp) => resp);
	}

	async deleteComment({comment_id}) {
		if (!isBrowser) {
			return;
		}
		return this.apiClient.delete(`/comments/?comment_id=${comment_id}`).then(
			({ data }) => {
				return data
			},
			(err) => {
				console.error(err)
				return { error: err }
			}
		)
	}
	/* ----- Client Configuration ----- */

	/* Create Axios client instance pointing at the REST api backend */
	getApiClient(config) {
		const initialConfig = {
			baseURL: `${config.apiBasePath}/api/v1`,
		};
		const client = axios.create(initialConfig);
		// Instantiate the interceptor (you can chain it as it returns the axios instance)
		createAuthRefreshInterceptor(client, refreshAuthLogic);
		client.interceptors.request.use(
			(config) => {
				const token = localStorage.getItem('token');
				if (token) {
					const parsedToken = JSON.parse(token);
					config.headers = {
						Authorization: `Bearer ${parsedToken.access_token}`,
						Accept: 'application/json',
						'Content-Type': 'application/json',
					};
				}
				return config;
			},
			(error) => {
				Promise.reject(error);
			}
		);

		return client;
	}

	/* Create Axios client instance pointing at the REST api backend */
	getApiClientEmpty(config) {
		const initialConfig = {
			baseURL: `${config.apiBasePath}/api/v1`,
		};
		const client = axios.create(initialConfig);
		return client;
	}

	/* ----- Code Labs ------ */
	// Code Execution
	createCodeSubmission(sourceCode, expectedOutput, codeExecutionBackend, languageID){
		return this.apiClient.post(`/code/submissions`, {
			source_code: sourceCode,
			language_id: languageID,
			expected_output: expectedOutput,
			code_execution_backend: codeExecutionBackend
		})
			.then((resp) => resp);
	}

	getCodeSubmission(codeSubmissionToken, codeExecutionBackend){
		return this.apiClient.post(`/code/submissions/poll`,{
			token: codeSubmissionToken,
			code_execution_backend: codeExecutionBackend
		}).then(
			(resp) => resp
		);
	}

	getSupportedLanguages(){
		return this.apiClient.get(`/code/supported-languages`).then(
			(resp) => resp
		);
	}


	/**
	 * @typedef {Object} CodeFile
	 * @property {string} path
	 * @property {string} source_code
	 */

	/**
	 * @typedef {Object} SupportedLanguageV2
	 * @property {string} coursemaker_id
	 * @property {string} title
	 * @property {string} default_example_additional_files
	 * @property {string} default_file_extension
	 * @property {string} default_test_file_name
	 * @property {string} ace_editor_mode
	 * @property {boolean} enable_live_autocompletion
	 * @property {CodeFile[]} code_files
	 */

	/**
	 * @typedef {Array<SupportedLanguageV2>} GetSupportedLanguageV2Response
	 */

	/**
	 * @return {Promise<{data: GetSupportedLanguageV2ResponseV2}>}
	 */
	getSupportedLanguagesV2(){
		function parseFilesAsync(resp){
			return Promise.all(resp.data.map(lang =>
				jszip.loadAsync(lang.default_example_additional_files_with_tests || lang.default_example_additional_files, {base64:true})
					.then(f => {
						const promises = [];
						f.forEach((relpath, file) => {
							promises.push(
								file.async('text').then(source_code => ({path: relpath, source_code})));
						});

						return Promise.all(promises);
					})
					.then(code_files => ({
						...lang,
						code_files,
						default_test_file_name: "/src/tests/test"
					}))))
				.then(data => ({
					...resp,
					data,
				}))
		}

		return this.apiClient.get(`/code/supported-languages/v2`)
			.then(resp => parseFilesAsync(resp));
	}

	/**
	 * @typedef CreateCodeSubmissionV2Response
	 * @property {string} token
	 */

	/**
	 *
	 * @param {{path: string, source_code: string}[]} files
	 * @param {string} coursemaker_id
	 * @param {string} expectedOutput
	 * @param {boolean} runTests
	 * @returns {Promise<{data: CreateCodeSubmissionV2Response}>}
	 */
	createCodeSubmissionV2(
		files,
		coursemaker_id,
		expectedOutput,
		runTests
	) {
		if (!coursemaker_id) {
			return Promise.reject("No CourseMaker id")
		}

		return this.apiClient
			.post(`/code/submissions/v2`, {
				files: files,
				coursemaker_id: coursemaker_id,
				expected_output: expectedOutput,
				run_tests: runTests,
			})
			.then((resp) => resp)
	}

	/**
	 * @typedef {Object} GetCodeSubmissionV2Response
	 * @property {string} stdout
	 * @property {string} compile_output
	 * @property {{id: number}} status
	 * @property {{failed: string[], passed: string[], errored: string[], raw_output: string}} test_results
	 */

	/**
	 *
	 * @param {string} codeSubmissionToken
	 * @param {string} coursemaker_id
	 * @returns {Promise<{data: GetCodeSubmissionV2Response}>
	 */
	getCodeSubmissionV2(codeSubmissionToken, coursemaker_id) {
		return this.apiClient
			.post(`/code/submissions/poll/v2`, {
				token: codeSubmissionToken,
				coursemaker_id,
			})
			.then((resp) => resp)
	}


	/* ----- Payment ----- */
	getPlatformStripePK() {
		return this.apiClient.get(`/payments/pk`).then(({ data }) => data.pk);
	}

	getSchoolStripeID() {
		return this.apiClient
			.get(`/schools/${this.config.schoolExternalId}/stripe-account-id`)
			.then((response) => response.data.id);
	}

	getPaddleVendorID() {
		return this.apiClient
			.get(`/schools/${this.config.schoolExternalId}/paddle-vendor-id`)
			.then((response) => response.data.id);
	}

	async createEnrollment(course, couponCode= null) {
		let schoolPriceID = null;
		try {
			schoolPriceID = parseInt(course?.price_info?.id);
		} catch {
			throw 'No school price set';
		}
		const body = JSON.stringify({
			school_price_id: schoolPriceID,
			success_url: `${window.location.origin}/success/?course_id=${course.id}`,
			cancel_url: `${window.location.origin}/cancel/`,
			coupon_code: couponCode,
		});
		return this.apiClient.post(`/students/me/enrolments`, body).then(({ data }) => data);
	}

	/* ---- Media -----*/
	signVideoURL(videoID) {
		return this.apiClient.get(`media/video/${videoID}/sign`).then(({ data }) => data);
	}
}

export function formatError(err){
	// TODO: We should make this work for CMS API errors too and try to use this in all catch blocks

	const detail = err?.response?.data?.detail
	if(typeof(detail) === 'string'){
		// If simple string error message
		return detail
	}else if(typeof(detail) === 'object'){
		// If standard backend formatted validation error
		// Example error format: [{"loc":["body","custom_domain_in","school_id"],"msg":"str type expected","type":"type_error.str"}]
		return `Error: `+ detail.map((d) => `the field ${d.loc.slice(-1)[0]} has the following error: ${d.msg}`).join(' and ')
	}else if(typeof(err) === 'object'){
		// If unknown JSON
		return JSON.stringify(err)
	}
	// If Javascript Error
	return err.toString()
}

export default FastAPIClient;